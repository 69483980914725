<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Budget Name</th>
          <th>Budget Code</th>
          <th>Project Name</th>
          <th>Business Line</th>
          <th>Period</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>Budget</td>
        <td>#B4545</td>
        <td>Project</td>
        <td></td>
        <td>12/06/2022-20/07/2022</td>
        <td>Active</td>
        <td>View</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
